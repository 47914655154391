/* main.scss */
$custom-background: #fff;

.content-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  display: block;

  padding-left: 5rem;
  padding-right: 5rem;

  header {
    display: grid;

  }

  article {
    display: grid;
    place-items: center;
  }

  section > h2 {
    display: grid;
    place-items: center;

    font-size: 32px;
    font-weight: bold;
  }

  z-index: 2;
}


.articleImage {
  height: 250px;
  width: auto;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.nav {
  .active {
    font-weight: bold;
  }

  ul {
    display: flex;
    gap: 1rem;
    font-size: 0.9rem;
    list-style: none;
    padding: 0;
  }

  li {
    cursor: pointer;
  }

  li:hover {
    text-decoration: underline;
  }

  align-items: center;
  position: sticky;
  flex-shrink: 0;
  top: -5px;
  z-index: 3;
  min-height: 70px;
  width: calc(100% - 2rem);
  background-color: $custom-background;
  padding: 0 1rem;
  box-shadow: 0 0.125rem 0.25rem 0 rgb(0 0 0 / 11%);
}

.nav__container__actions {
  display: inline-flex;
  flex-flow: row nowrap;
  padding-left: 50px;
  flex: 1;
}

.lead {
  padding: 2em;
  margin: 1em;

  align-items: center;
  justify-content: center;

  display: block;

  background-color: $custom-background;
  background-image: url('./layered-waves-haikei.svg');
  aspect-ratio: 960/140;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.article {
  padding: 2em;
  margin: 1em;

  display: block;

  background-color: $custom-background;
}

.header {
  padding: 1em;
  margin: auto 0;

  display: block;

  background-color: $custom-background;
}

.footer {
  align-items: center;

  padding: 1em;
  margin: auto 0;
  z-index: 3;

  background-color: $custom-background;
}

.footer-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.column {

}


img {
  width: 100%;
}


main {
  flex-grow: 1;
}

header, main, footer {
  flex-shrink: 0;
}